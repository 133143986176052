import React from "react";
import { Helmet } from 'react-helmet-async';

const Pedido = () =>{
    return(
        <>
            <Helmet><title>Mi Cuenta</title></Helmet>
        </>
    )
}

export default Pedido;