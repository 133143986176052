import React, { useEffect, useState } from 'react';
import './sidebar.css';
import MenuAdmin from '../../data/menus/MenuAdmin.json';
import MenuAliada from '../../data/menus/MenuAliadas.json';
import MenuSuper from '../../data/menus/MenuSuper.json';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as DropDown } from '../../img/icons/arrow-down.svg';
import { ReactComponent as Out } from '../../img/icons/logout.svg';
import { useAuth } from '../../context/AuthContext';
import SVG from 'react-inlinesvg';

const SideBar = () => {

    const {infoUser, singOut} = useAuth();
    const [admin, setAdmin] = useState('');
    const [superA, setSuperA] = useState('');
    const [aliada, setAliada] = useState('');

    useEffect(() => {
        if(infoUser){
            if(infoUser.rol === 'admin'){setAdmin(true)}
            if(infoUser.rol === 'superaliada'){setSuperA(true)}
            if(infoUser.rol === 'aliada'){setAliada(true)}
        }
    }, [infoUser])

    const location = useLocation();
	const { pathname } = location;
    const splitLocation = pathname.split("/");
    const [locationN, setLocationN] = useState('')
    const [locationS, setLocations] = useState('')

    useEffect(()=>{
        setLocationN(splitLocation[1])
        setLocations(splitLocation[2])
    },[splitLocation])

    const [activeSub, setActiveSub] = useState();

    const RenderMenu = (menu) =>{
        return(
            menu.length > 0 && menu.map((link) =>{
                return(
                    <div key={link.id} className='levelZ'>
                        <div className="levelA">
                            <Link to={('/'+link.link)}  className={locationN === link.id ? "liActivo" : ""}
                                onClick={()=>{setActiveSub(link.id)}}><SVG src={link.img}/>{link.text}</Link>
                                {link.submenu && <DropDown className={activeSub === link.id ? 'DropDownMenuA':'DropDownMenu' }
                                    onClick={()=>{setActiveSub(activeSub === link.id ? '' : link.id )}}/>}
                        </div>
                        {link.submenu && 
                            <div className={activeSub === link.submenuLink ? "levelB levelBAct" : "levelB"}>
                                {link.submenu.map((submenu)=>{
                                    return(
                                        <Link to={('/'+submenu.link)} key={submenu.id} className={locationS=== submenu.id ? "liActivo" : ""}>
                                            <SVG src={submenu.img}/>{submenu.text}</Link>
                                    )
                                })} 
                            </div>
                        }
                    </div>
                )
            })
        )
    }

    return (
        <div className='sideBar'>
            <div className='items'>
                {admin && RenderMenu(MenuAdmin)}
                {superA && RenderMenu(MenuSuper)}
                {aliada && RenderMenu(MenuAliada)}
            </div>
            <div className='items'>
                <button className="liSideItem" onClick={singOut}>
                    <Out className='icon' /><span>Salir</span>
                </button>
            </div>
            
        </div>
    )
}

export default SideBar