import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter} from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { ConfigProvider } from './context/ConfigsContext';
import Router from './router/Router';

import './styles/index.css';
import './styles/App.css';
import './styles/bootstrap-grid.css';
import './styles/bootstrap-grid.css.map';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <ConfigProvider>
          <AuthProvider>
            <Router />
          </AuthProvider>
        </ConfigProvider>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);