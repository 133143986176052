import React from 'react'
import { useConfig } from '../../context/ConfigsContext';
import './error404.css';

const Error404 = () => {

  const {theme} = useConfig();

  return (
    <div className='errorPage' data-theme={theme}>
      Error404
    </div>
  )
}

export default Error404;