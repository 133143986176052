import React, { useState, useContext, useEffect } from 'react';
import { auth, db } from '../firebase/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { getUnixTime } from 'date-fns';
import { signOut } from 'firebase/auth';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { LoadingPage } from '../components/loading/Loadings';

const AuthContext = React.createContext();

const useAuth = () => {
    return useContext(AuthContext);
}

const AuthProvider = ({children}) => {
    const [usuario, setUsuario] = useState();
    const [cargando, setCargando] = useState(true);
    const [infoUser, setInfoUser] = useState();
    const navigate = useNavigate();

    const singOut = async () => {
        await signOut(auth).then(() => {
            setUsuario();
            setInfoUser();
            window.scrollTo({ behavior: 'smooth', top: '0px' });
            navigate('/');
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(()=>{
        const unsuscribe = auth.onAuthStateChanged(async(user) => {
            setUsuario(user);
            setCargando(false);
        });
        return unsuscribe;
    }, []);

    useEffect(()=>{
        if(usuario){
            if(!usuario.emailVerified){
                navigate('/verificar-email');
            }else{
                if(!infoUser){
                    getDoc(doc(db, 'users', usuario.uid))
                    .then((docSnap =>{
                        if (docSnap.exists()) {
                            setInfoUser(docSnap.data())
                            updateDoc(doc(db, 'users', usuario.uid), {
                                lastSign: getUnixTime(new Date())
                            })
                            // if(!docSnap.data().city){
                            //     navigate('/terminar-registro')
                            // }
                        }
                    }))
                }
            }
        }
    },[usuario, navigate, infoUser]);

    return(
        <AuthContext.Provider value={{usuario, infoUser, singOut, setInfoUser}}>
            {cargando ? <LoadingPage /> : children}
        </AuthContext.Provider>
    );
}

export {AuthProvider, AuthContext, useAuth};