import React, { useState } from "react";
import { Helmet } from 'react-helmet-async';
import { InputIco } from "../../components/inputs/Input";
import { useParams } from 'react-router-dom';

const Producto = () =>{

    const [productName, setProductName] = useState('');
    const [altName, setAltName] = useState('');
    const [spnName, setSpnName] = useState('');

    const { id } = useParams();
    
    return(
        <>
            <Helmet><title>Producto {id}</title></Helmet>
            <InputIco name={'name'} type={'text'} label={'Nombre'} value={productName} setAlt={setAltName}
                setValue={setProductName} alt={altName} spn={spnName} setSpn={setSpnName} fill={'on'} />
        </>
    )
}

export default Producto;