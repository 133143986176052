import React from 'react'
import { useConfig } from '../../context/ConfigsContext'

const Modal = () => {

  const { CloseModal, modalStatus, modal, ComponentM, theme } = useConfig();

  return modalStatus &&
    <div className={modal} data-theme={theme}>
      <ComponentM />
      <div onClick={CloseModal}></div>
    </div>
    
}

export { Modal }