import React from "react";
import { useConfig } from './../../context/ConfigsContext';
import { Modal } from './../../components/modales/Modales';
import './Login.css';
import { useAuth } from "../../context/AuthContext";
import { Navigate } from "react-router-dom";
import { LoadingPage } from "../../components/loading/Loadings";
import Theme from "../../components/theme/Theme";

const LoginUsuario = ({ Component }) =>{

    const { usuario, cargando } = useAuth();
    const { theme } = useConfig();

    return cargando ? <LoadingPage /> : usuario ? <Navigate to="/dashboard"/> :
        <div className="LoginPage" data-theme={theme}>
            <div className="LoginContent">
                <div className="LoginDiv">
                    <div className="LoginNotice">
                    </div>
                    <div className="LoginForm">
                        <Component />
                    </div>
                </div>
                <div className="LoginBottom">
                    <Theme />
                </div>
            </div>
            <Modal />
        </div>
    
}

export default LoginUsuario;