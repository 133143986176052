import { db } from '../firebase/firebaseConfig';
import { doc, setDoc } from "firebase/firestore";

const RegisterNames = async(props) =>{
    const{
        userId,
        rol,
        names,
        email
    } = props;

    const registro = await setDoc(doc(db, 'users', userId), {
        userId: userId,
        rol: rol,
        name: names,
        email: email
    });

    return registro;
} 

const RegisterUser = (props) => {
    const{
        userId,
        rol,
        names,
        email,
        phone,
        address,
        city,
        town,
        docId,
        expDate,
        birthDate,
        birthDay,
        joinDate,
        condition
    } = props;

    return db.collection('users').add({
        userId: userId,
        rol: rol,
        names: names,
        email: email,
        phone: phone,
        address: address,
        city: city,
        town: town,
        docId: docId,
        expDate: expDate,
        birthDate: birthDate,
        birthDay: birthDay,
        joinDate: joinDate,
        condition: condition
    });
}

export { RegisterUser, RegisterNames };