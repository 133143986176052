import React from "react";
import { useConfig } from "./context/ConfigsContext";
// import Header from './components/header/Header'

const App = ({Component}) => {

  const {theme} = useConfig();

  return (
    <div data-theme={theme}>
      {/* <Header /> */}
      <Component />
    </div>
  );
}

export default App;
