import React from "react"

const CorreoVerificacion = ({Mensaje}) =>{
    return(
        <><p>{Mensaje}</p></>
    )
}

const HuboUnError = ({Mensaje}) =>{
    return(
        <><p>{Mensaje}</p></>
    )
}

const CorreoClave = () =>{
    return(
        <></>
    )
}

export {
    CorreoVerificacion,
    CorreoClave,
    HuboUnError
}