import React from 'react'
import '../../styles/pages.css';
import { Link } from 'react-router-dom';

const Inicio = () => {

  return (
    <div className='pageGeneral'>
      <Link to={'/ingresar'} className='buttonLink'>Ingresar</Link>
    </div>
  )
}

export default Inicio;