import React from "react";
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

const MiPedido = () =>{

    const { id } = useParams();

    return(
        <>
            <Helmet><title>Pedido {id}</title></Helmet>
        </>
    )
}

export default MiPedido;