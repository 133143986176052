import React from "react";
import { Helmet } from 'react-helmet-async';
import { useParams } from "react-router-dom";

const Aliada = () =>{

    const { id } = useParams();

    return(
        <>
            <Helmet><title>Aliada {id}</title></Helmet>
        </>
    )
}

export default Aliada;