import React from "react";
import { Helmet } from 'react-helmet-async';

const InicioAdmin = () =>{
    return(
        <>
            <Helmet><title>Inicio Admin</title></Helmet>
            <p>Inicio Admin</p>
        </>
    )
}

export default InicioAdmin;