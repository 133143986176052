import React from "react";
import { Helmet } from 'react-helmet-async';

const Aliadas = () =>{
    return(
        <>
            <Helmet><title>Aliadas</title></Helmet>
        </>
    )
}

export default Aliadas;