import React from "react";
import { Helmet } from 'react-helmet-async';
import NuevoProducto from "../components/NuevoProducto";


const Productos = () =>{
    return(
        <>
            <Helmet><title>Productos</title></Helmet>
            <NuevoProducto />
            <div className="card">sdfasd</div>
        </>
    )
}

export default Productos;