import React, { useState } from 'react';
import { auth} from '../../../firebase/firebaseConfig';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useConfig } from '../../../context/ConfigsContext';
import { HuboUnError } from '../../../components/mensajes/MensajesClient';
import { InputPass, Input } from '../../../components/inputs/Input';
import { ButtonLoad } from '../../../components/buttons/Boton';
import { RegisterNames } from '../../../data/RegisterUser';
import { createUserWithEmailAndPassword } from "firebase/auth";

const Register = () =>{

    const navigate = useNavigate();
    const { OpenModal, CloseModal } = useConfig();
    const [btnRegistro, setBtnRegistro] = useState(false);

    const[nombre, setNombre] = useState('');
    const[apellido, setApellido] = useState('');
    const[email, setEmail] = useState('');
    const[password, setPassword] = useState('');
    const[password2, setPassword2] = useState('');

    //ALERTAS
    const[altNombre, setAltNombre] = useState('');
    const[altApellido, setAltApellido] = useState('');
    const[altEmail, setAltEmail] = useState('');
    const[altPassOne, setAltPassOne] = useState('');
    const[altPassTwo, setAltPassTwo] = useState('');
    const[altTerms, setAltTerms] = useState('');
    const[spnNombre, setSpnNombre] = useState('');
    const[spnApellido, setSpnApellido] = useState('');
    const[spnEmail, setSpnEmail] = useState(false);
    const[spnPassOne, setSpnPassOne] = useState(false);
    const[spnPassTwo, setSpnPassTwo] = useState(false);
    const[spnTerms, setSpnTerms] = useState(false);

    const EmailTest = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;
    const EditText = (text) =>{ const id = text.replace(/\s+/g, '').toLocaleLowerCase(); return id;}

    //ENVIO DE FORMULARIO
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(nombre === ''){setSpnNombre(true); setAltNombre('Por favor ingresa tu Nombre'); return;}
        if(apellido === ''){setSpnApellido(true); setAltApellido('Por favor ingresa tu Apellido'); return;}
        if(email === ''){setSpnEmail(true); setAltEmail('Por favor ingresa un correo electrónico'); return;}
        if(!EmailTest.test(email)){setSpnEmail(true); setAltEmail('Por favor ingresa un correo válido'); return;}
        if(password === ''){setSpnPassOne(true); setAltPassOne('Por favor ingresa una contraseña'); return;}
        if(password.length < 6){
            setSpnPassOne(true); setAltPassOne('La contraseña tiene que ser de al menos 6 caracteres'); return;}
        if(password2 === ''){setSpnPassTwo(true); setAltPassTwo('Por favor repite tu contraseña'); return;}
        if(password !== password2){setSpnPassTwo(true); setAltPassTwo('Las contraseñas no son iguales'); return;}
        setBtnRegistro(true);

        createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            RegisterNames({
                userId: userCredential.user.uid,
                rol: 'aliada',
                names:{
                    name: EditText(nombre),
                    lastName: EditText(apellido),
                    segName: '',
                    segLast: ''
                },
                email: email
            }).then(()=>{
                navigate('/terminar-registro')
            }).catch(()=>{
                OpenModal(<HuboUnError CloseModal={CloseModal}/>)
            });
        }).catch((error) => {
            setBtnRegistro(false);
            switch(error.code){
                case 'auth/email-already-in-use': setSpnEmail(true); setAltEmail('Ya existe una cuenta con ese correo'); break;
                case 'auth/invalid-email': setSpnEmail(true); setAltEmail('El correo electrónico no es válido'); break;
                case 'auth/invalid-password': setSpnPassOne(true); setAltPassOne('La contraseña tiene que ser de al menos 6 caracteres'); break;
                default: setSpnTerms(true); setAltTerms('Hubo un error al intentar crear la cuenta'); break;
            }
        })       
    }

    return (
        <>
            <Helmet><title>Regístrate</title></Helmet>
            <form onSubmit={handleSubmit}>
                <h1 className='formHeader'>Crea tu Cuenta</h1>
                <h2 className='formSub mb-4'>Conviértete en una Aliada Selvática</h2>
                <div className=''>
                    <div className='flexDiv'>
                        <Input name={'name'} type={'text'} label={'Nombre'} value={nombre} 
                        setValue={setNombre} alt={altNombre} spn={spnNombre} setSpn={setSpnNombre} fill={'on'} />
                        <Input name={'lastName'} type={'text'} label={'Apellido'} value={apellido} 
                            setValue={setApellido} alt={altApellido} spn={spnApellido} setSpn={setSpnApellido} fill={'on'} />
                    </div>

                    <Input name={'emaillogin'} type={'text'} label={'Correo Electrónico'} value={email} 
                        setValue={setEmail} alt={altEmail} spn={spnEmail} setSpn={setSpnEmail} fill={'on'} />

                    <InputPass name={'password'} label={'Contraseña'} value={password} 
                        setValue={setPassword} alt={altPassOne} spn={spnPassOne} setSpn={setSpnPassOne} fill={'off'} />

                    <InputPass name={'password2'} label={'Repite la contraseña'} value={password2}
                        setValue={setPassword2} alt={altPassTwo} spn={spnPassTwo} setSpn={setSpnPassTwo} fill={'off'} />
                </div>
                <p className="legalText py-4">
                    Creando tu cuenta, estarás aceptando nuestras <Link to="/politicas-de-privacidad">políticas de privacidad
                    </Link> y nuestros <Link to="/terminos-y-condiciones">términos y condiciones</Link> .
                </p>
                {spnTerms && <div className='alertForm'><p>{altTerms}</p></div>}
                <ButtonLoad type={"submit"} disable={btnRegistro} text={'Registrarme'} />

                <p className="legalText py-4">¿Ya tienes una cuenta? <Link to="/ingresar">Ingresa</Link></p>
            </form>
        </>
    )
}

export default Register;