import React, {useEffect, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { auth } from '../../firebase/firebaseConfig';
import { useConfig } from '../../context/ConfigsContext';
import { Modal } from '../../components/modales/Modales';
import { sendEmailVerification } from 'firebase/auth';
import { CorreoVerificacion, HuboUnError } from '../../components/mensajes/MensajesClient';

const VerificarEmail = () =>{

    const navigate = useNavigate();
    const {usuario, cerrarSesion} = useAuth();
    const { OpenModal, theme } = useConfig();
    const [verificado, setVerificado] = useState(false);

    useEffect(() => {
        if(usuario){
            if(usuario.emailVerified){
                setVerificado(true);
                const timer1 = setTimeout(()=>{
                    navigate('/terminar-registro');
                }, 50000);
                return () => {clearTimeout(timer1);};  
            }else{
                setVerificado(false);
            }
        }
    }, [usuario, navigate])

    const EnviarCorreo = () =>{
        sendEmailVerification(auth.currentUser).then(()=>{
            const Mensaje = 'El link de verificación fue enviado a tu correo electrónico';
            OpenModal(<CorreoVerificacion Mensaje={Mensaje}/>)
        }).catch(() =>{
            const Mensaje = 'Algo salió mal, por favor intentálo de nuevo';
            OpenModal(<HuboUnError Mensaje={Mensaje}/>)
        });
    }
    
    return(
        <>
            <div className="ContLog" data-theme={theme}>
                <div className="ContLogBd">
                    <div className="ContVerify">
                        <div className="ContHeads">
                            <div className="IconContext">
                            </div>
                        </div>
                        {!verificado ? <>
                            <h3>Verifica tu Email</h3>
                            <p>Hemos enviado un correo electrónico para verificar tu Email.<br/>
                            ¿No lo has recibido? <button className="" onClick={()=>{EnviarCorreo()}}>Reenviar Correo</button></p>
                            <button className="boton-general" onClick={()=>{window.location.reload();}}>Verificar Email</button>
                        </>:<>
                            <h3 className="TextGreen">Email Verificado</h3>
                            <p>Termina de completar tu registro</p>
                            <Link to="/terminar-registro" className='boton-general'>Completar Registro</Link>
                        </>}
                    </div>
                    <div className="divSalir">
                        <p>¿Deseas verificar tu correo luego?</p>
                        <button type="button" onClick={cerrarSesion} className="boton-text imgButton-rigth">Salir</button>  
                    </div>
                </div>
                
            </div>
            <Modal />
        </>
    )
}

export default VerificarEmail;