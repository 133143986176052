import React, { useState } from "react";
import './Input.css';
import {ReactComponent as Show} from '../../img/icons/eye.svg';
import {ReactComponent as Shown} from '../../img/icons/eye-slash.svg';

const InputDisabled  = (props) =>{

    const{
        name,
        type,
        label,
        value
    }= props;

    return(
        <div className='inputDisabled'>
            <input name={name} type={type} placeholder=" " value={value} disabled={true} />
            <label>{label}</label>
        </div>
    )
}

const Input = (props) =>{

    const{
        name,
        type,
        label,
        value,
        setValue,
        alt,
        spn,
        setSpn,
        fill
    }= props;

    const handleChange = (e) =>{ setValue(e.target.value); setSpn(false); }

    return(
        <div className={!spn ? 'input': 'input redLine'}>
            <input name={name} type={type} placeholder=" " value={value} onChange={handleChange} autoComplete={fill} />
            <label>{label}</label>
            {spn && <span className="spanAlert">{alt}</span>}
        </div>
    )
}

const InputOptional = (props) =>{
    const{
        name,
        type,
        label,
        value,
        setValue,
        fill
    }= props;

    const handleChange = (e) =>{ setValue(e.target.value);}

    return(
        <div className='input'>
            <input name={name} type={type} placeholder=" " value={value} onChange={handleChange} autoComplete={fill} />
            <label>{label}</label>
        </div>
    )
}

const InputIco = (props) =>{

    const{
        name,
        type,
        label,
        value,
        setValue,
        alt,
        spn,
        setSpn,
        fill
    }= props;

    const handleChange = (e) =>{ setValue(e.target.value); setSpn(false); }

    return(
        <div className={!spn ? 'input': 'input redLine'}>
            <input name={name} type={type} placeholder=" " value={value} onChange={handleChange} autoComplete={fill} />
            <label>{label}</label>
            {spn && <span className="spanAlert">{alt}</span>}
        </div>
    )
}

const InputPass = (props) =>{

    const {
        name,
        label,
        value,
        setValue,
        alt,
        spn,
        setSpn,
        fill 
    }= props;

    const [show, setShow] = useState(false);
    const handleShow = () =>{ setShow(!show); }

    const handleChange = (e) =>{setValue(e.target.value); setSpn(false);}

    return(
        <div className={!spn ? 'input': 'input redLine'}>
            <input name={name} className={!show ? 'dotsPass':' '} type={show ? 'text' : 'password'} placeholder=" " value={value} onChange={handleChange} autoComplete={fill} />
            <label>{label}</label>
            {spn && <span className="spanAlert">{alt}</span>}
            <button className='inputIcon' onClick={handleShow} type="button">{show ? <Shown className='icon'/> : <Show className='icon'/>}</button>
        </div>
    )
}

const InputPhone = () =>{
    return(
        <input />
    )
}

export{
    Input,
    InputOptional,
    InputIco,
    InputPhone,
    InputPass,
    InputDisabled
}