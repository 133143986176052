import React from "react";
import './Boton.css';

const Button = ({text, disabled, type, action}) =>{
    return (
        <div className="ButtonLoadDiv">
            <button className="buttonLink btnLarge" disabled={disabled} type={type} onClick={action}>{text}</button>
        </div>
    )
}

const ButtonLoad = (props) =>{

    const{
        type,
        disable,
        text,
    } = props;

    return (
        <div className="ButtonLoadDiv">
            <button type={type} className="buttonLink btnLarge" disabled={disable}>
                {disable ? 'Cargando...' : text}</button>
        </div>
    )
}

const BtnTxt = ({Text, Disabled, Type, Color, Id}) =>{
    return(
        <button className={Color+' '} disabled={Disabled} type={Type}>{Text}</button>
    )
}

const BtnImgR = ({Text, Disabled, Type, Color, Id, Img}) =>{
    return(
        <button className={Color+' '} disabled={Disabled} type={Type}>
            <img src={Img} alt={Img} />{Text}</button>
    )
}

const BtnImgL = ({Text, Disabled, Type, Color, Img}) =>{
    return(
        <button className={Color+' '} disabled={Disabled} type={Type}>
            {Text}<img src={Img} alt={Img} /></button>
    )
}

export{
    Button,
    BtnTxt,
    BtnImgR,
    BtnImgL,
    ButtonLoad
}