import React from "react";
import { Helmet } from 'react-helmet-async';

const PedidosAliadas = () =>{
    return(
        <>
            <Helmet><title>Pedidos</title></Helmet>
        </>
    )
}

export default PedidosAliadas;