import React, {useEffect, useState} from 'react'
import { Avatar } from '../avatar/Avatar'
import { useAuth } from '../../context/AuthContext';
import { ReactComponent as DropDown } from '../../img/icons/arrow-square-down.svg';
import './AvatarDropdown.css';

const AvatarDropdown = () => {

    const {usuario, infoUser} = useAuth();
    const [userName, setUserName] = useState();
    const [lastName, setLastName] = useState();
    const [rol, setRol] = useState();

    function capitalize(word) {return word[0].toUpperCase() + word.slice(1).toLowerCase();}

    useEffect(()=>{
        if(usuario){
            setUserName(capitalize(infoUser.name.name))
            setLastName(capitalize(infoUser.name.lastName))
            if(infoUser.rol === 'admin'){setRol('Administrador')}else{setRol(capitalize(infoUser.rol))}
        }
    },[usuario, infoUser])

    return (
        <div className='AvatarDrop'>
            <Avatar/>
            <p className='spanName'>{userName+' '+lastName}<br/><span>{rol}</span></p>
            <button className="buttonIcon"><DropDown className='icon' /></button>
        </div>
    )
}

export default AvatarDropdown;