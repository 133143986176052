import React from "react";
import Footer from '../footer/Footer';
import { Modal } from '../modales/Modales';
import Header from "../header/Header";
import SideBar from "../menu/SideBar";
import { useConfig } from "../../context/ConfigsContext";
import './dashboard.css'

const Dashboard = ({ Component }) =>{

    const { theme } = useConfig()

    return(
        <>
            <div className="dashboard" data-theme={theme}>
                <Header />
                <div className="dashInner">
                    <SideBar/>
                    <div className="dashContent">
                        <Component/>    
                        <Footer/>   
                    </div>
                </div>
            </div>
            <Modal />
        </>
    )
}

export default Dashboard;