import React, { useState } from 'react';
import { ButtonLoad } from '../../components/buttons/Boton';
import { Input } from '../../components/inputs/Input';

const NuevoProducto = () => {

  const[productID, setProductID] = useState('');
  const[product, setProduct] = useState('');
  const[price, setPrice] = useState('');

  const[altProducID, setAltProductID] = useState('');
  const[altProduct, setAltProduct] = useState('');

  const[spnProducID, setSpnProductID] = useState(false)
  const[spnProduct, setSpnProduct] = useState(false)

  const handleSubmit = () =>{
    
    setAltProductID('')
    setAltProduct('')
  }

  return (
      <div className='card'>
          <form onSubmit={handleSubmit}>
            <div className='flexDiv'>
              <Input name={'productID'} type={'text'} label={'Código'} value={productID} 
                setValue={setProductID} alt={altProducID} spn={spnProducID} setSpn={ setSpnProductID} fill={'off'} />
              <Input name={'name'} type={'text'} label={'Nombre'} value={product} 
                setValue={setProduct} alt={altProduct} spn={spnProduct} setSpn={setSpnProduct} fill={'on'} />
            </div>
            <Input name={'price'} type={'text'} label={'Valor'} value={price} 
                setValue={setPrice} alt={altProduct} spn={spnProduct} setSpn={setSpnProduct} fill={'on'} />
            <ButtonLoad text={'Agregar'} disabled={false} type={'submit'} />
          </form>
          
      </div>
    
  )
}

export default NuevoProducto