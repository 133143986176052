import React, {useState, useEffect} from "react";
import { InputDisabled, Input, InputOptional } from '../../components/inputs/Input';
import { Button } from "../../components/buttons/Boton";
import { useAuth } from "../../context/AuthContext";
import { useConfig } from "../../context/ConfigsContext";

const TerminarRegistro = () =>{

    const { infoUser, SingOut } = useAuth();
    const { theme } = useConfig();

    const [stepOne, setStepOne] = useState(true);
    const [stepTwo, setStepTwo] = useState(false);
    const [stepThr, setStepThr] = useState(false);

    const [userName, setUserName] = useState('');
    const [secName, setSecName] = useState('');
    const [lastName, setLastName] = useState('');
    const [secLast, setSecLast] = useState('');
    const [email, setEmail] = useState('');
    const [docId, setDocId] = useState('');

    const [spnDocId, setSpnDocId] = useState(false);
    const [altDocId, setAltDocId] = useState('');

    useEffect(()=>{
        if(infoUser){
            setEmail(infoUser.email)
            setUserName(infoUser.name.name);
            setLastName(infoUser.name.lastName);
        }
    },[infoUser])

    const setPassOne = () =>{setStepOne(true); setStepTwo(false); setStepThr(false)}
    const setPassTwo = () =>{setStepOne(false); setStepTwo(true); setStepThr(false)}
    const setPassThr = () =>{setStepOne(false); setStepTwo(false); setStepThr(true)}

    const handleOne = (e) =>{
        e.preventDefault();
        if(docId === ''){setSpnDocId(true); setAltDocId('Por favor ingresa tu Documento'); return;}
        setPassTwo()
    }
    const handleTwo = (e) =>{
        e.preventDefault();
        setPassThr()
    }

    const handleSubmit = (e) =>{
        e.preventDefault();
        if(docId === ''){setSpnDocId(true); setAltDocId('Por favor ingresa tu Documento'); setPassOne(); return;}
    }

    return (
        <div data-theme={theme}>
            <form className="" onSubmit={handleSubmit}>
                <div className="">

                </div>
                <div className="">
                    {stepOne && 
                        <>
                            <div className="flexDiv">
                                <InputDisabled name={'name'} type={'text'} label={'Nombre'} value={userName} />
                                <InputOptional name={'secName'} type={'text'} label={'Segundo Nombre'} value={secName} 
                                    setValue={setSecName} fill={'on'} />
                            </div>
                            <div className="flexDiv">
                                <InputDisabled name={'lastName'} type={'text'} label={'Apellido'} value={lastName} />
                                <InputOptional name={'secLast'} type={'text'} label={'Segundo Apellido'} value={secLast} 
                                    setValue={setSecLast} fill={'on'} />
                            </div>
                            <div className="flexDiv">
                                <Input name={'docNumber'} type={'number'} label={'Número de Documento'} value={docId} 
                                    setValue={setDocId} alt={altDocId} spn={spnDocId} setSpn={setSpnDocId} fill={'off'} />
                            </div>
                            <div className="">
                                <Button action={handleOne} text={'Siguiente'} disabled={false} type={'button'} />
                            </div>
                        </>
                    }
                    {stepTwo &&
                        <>
                            <div className="">
                                <InputDisabled name={'email'} type={'text'} label={'Correo Electrónico'} value={email} />
                                <Button action={setPassOne} text={'Regresar'} disabled={false} type={'button'} />
                                <Button action={handleTwo} text={'Siguiente'} disabled={false} type={'button'} />
                            </div>
                        </>
                    }
                    {stepThr &&
                        <>
                            <div className="">
                                <Button action={setPassTwo} text={'Regresar'} disabled={false} type={'button'} />
                                <Button action={handleSubmit} text={'Siguiente'} disabled={false} type={'submit'} />
                            </div>
                        </>
                    }
                    <button onClick={SingOut} disabled={false} type={'button'}> Salir</button>
                </div>
            </form>
        </div>
    )
}

export default TerminarRegistro;