import React, { useState, useContext, useEffect} from 'react';

const ConfigContext = React.createContext();

const useConfig = () => {
    return useContext(ConfigContext);
}

const ConfigProvider = ({children}) => {

    const [theme, setTheme] = useState('light');

    const SwitchTheme = () =>{
        const newTheme = theme === 'light' ? 'dark' : 'light';
        localStorage.setItem('theme', newTheme);
        setTheme(newTheme)
    }

    useEffect(()=>{
        const o = localStorage.getItem('theme');
        setTheme(o)
    },[])

    const [OpenMenu, setOpenMenu] = useState(false);

    const [modalStatus, setModalStatus] = useState(false);
    const [ComponentM, setComponentM] = useState();
    const [modal, cambiarModal] = useState('modal-general');

    const OpenModal = ({Component}) => {
        setModalStatus(true);
        setComponentM(Component);
        setTimeout(() => {
            cambiarModal('modal-general modal-activo');
        }, 400);
    }
    const CloseModal = (e) => {
        e.preventDefault();
        cambiarModal('modal-general')
        setTimeout(() => {
            setModalStatus(false)
        }, 600);
        setComponentM();
    }

    return(
        <ConfigContext.Provider 
        value={{
            CloseModal, 
            OpenModal,
            modalStatus,
            ComponentM,
            modal,
            OpenMenu,
            setOpenMenu,
            SwitchTheme,
            theme }}>
            {children}
        </ConfigContext.Provider>
    );
}

export {ConfigProvider, ConfigContext, useConfig};