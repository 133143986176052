import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const InicioAliadas = () => {

  const { infoUser} = useAuth();
  const navigate = useNavigate();

  useEffect(()=>{
    if(infoUser){
      if(infoUser.rol === 'admin'){navigate('/admin')}
      if(infoUser.rol === 'superaliada'){navigate('/superaliada')}
    }
  },[infoUser, navigate])

  return (
    <div>Inicio Aliadas</div>
  )
}

export default InicioAliadas;