import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
// import { useConfig } from '../../../context/ConfigsContext';
import { auth } from '../../../firebase/firebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { InputPass, Input } from '../../../components/inputs/Input';
import { Button } from '../../../components/buttons/Boton';

const SignIn = () => {

  // const { OpenModal, CloseModal } = useConfig();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [altEmail, setAltEmail] = useState('');
  const [altPass, setAltPass] = useState('');
  const [altTerms, setAltTerms] = useState('');
  const [spnEmail, setSpnEmail] = useState(false);
  const [spnPass, setSpnPass] = useState(false);
  const [spnTerms, setSpnTerm] = useState(false);

  const EmailTest = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;

  //FUNSIONES
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    if(email === ''){setSpnEmail(true); setAltEmail('Por favor ingresa un correo electrónico válido'); return; }
    if(!EmailTest.test(email)){setSpnEmail(true); setAltEmail('Por favor ingresa un correo electrónico válido'); return;}
    if(password === ''){setSpnPass(true); setAltPass('Por favor ingresa tu contraseña'); return;}

    try{
      signInWithEmailAndPassword(auth, email,password)
    } catch(error){
      switch(error.code){
        case 'auth/wrong-password': setSpnPass(true); setAltPass('La contraseña no es correcta.'); break;
        case 'auth/user-not-found': setSpnEmail(true); setAltEmail('No se encontró ninguna cuenta con ese correo.'); break;
        default: setSpnTerm(true); setAltTerms('Hubo un error al intentar ingresar a la cuenta.'); break;
      }
    };
  }

  return (
    <>
      <Helmet><title>Ingresar</title></Helmet>
      <form onSubmit={handleLoginSubmit}>
        <h1 className='formHeader'>¡Bienvenid@!</h1>
        <h2 className='formSub mb-4'>Nos alegra verte de nuevo por aquí.</h2>
        <Input name={'emaillogin'} type={'text'} label={'Correo Electrónico'} value={email} 
          setValue={setEmail} alt={altEmail} spn={spnEmail} setSpn={setSpnEmail} fill={'on'} />
        <InputPass name={'password'} label={'Contraseña'} value={password} setValue={setPassword} 
          alt={altPass} spn={spnPass} setSpn={setSpnPass} fill={'off'} />
        <p className='legalText py-4'><Link to="/recuperar-contrasena" className="textForgot">¿Olvidaste la contraseña?</Link></p>

        {spnTerms && <div className='alertForm'><p>{altTerms}</p></div>}
        <Button type={"submit"} disable={false} text={'Ingresar'} />
        <p className="legalText py-4">¿No tienes una Cuenta? <Link to="/registrate">Regístrate</Link></p>
      </form>
    </>
  )
}

export default SignIn;