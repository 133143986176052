import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import Dashboard from '../components/dashboard/Dashboard';
import { LoadingPage } from '../components/loading/Loadings';
import { Navigate } from 'react-router-dom';

const DashboardSuper = ({ Component }) => {
    
    const { usuario, infoUser } = useAuth();
    const [admin, setAdmin] = useState(false);
    const [cargando, setCargando] = useState(true);

    useEffect(()=>{
        if(usuario){
            if(infoUser){
                if(infoUser.rol === 'admin' || infoUser.rol === 'superaliada'){
                    setAdmin(true)
                    setCargando(false)
                }else{
                    setCargando(false)
                    setAdmin(false)
                }
            }
        }else{
            setCargando(false)
            setAdmin(false)
        }
    },[usuario, infoUser])

    return cargando ? < LoadingPage /> : admin ? <Dashboard Component={Component} /> : <Navigate to="/"/>

}

export default DashboardSuper;