import React from 'react';
import { useConfig } from './../../context/ConfigsContext';
import { ReactComponent as Sun }  from '../../img/icons/sun-1.svg';
import { ReactComponent as Moon } from '../../img/icons/moon.svg';

const Theme = () => {
    
  const {SwitchTheme, theme} = useConfig();

  return (
    <button className="buttonIcon" onClick={SwitchTheme}>
      {theme === 'dark' ? <Sun className='icon' /> : <Moon className='icon' />}
    </button>
  )
}

export default Theme;