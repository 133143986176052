import React from 'react';
import './header.css';
import { Link } from 'react-router-dom';
import Theme from '../theme/Theme';
import AvatarDropdown from './AvatarDropdown';
import {ReactComponent as Logo} from '../../img/logoAliadas.svg';

const Header = () => {

  return (
    <div className='navigate'>
      <Logo className='logoApp'/>
      <Theme />
      <div className='navRigth'>
        <AvatarDropdown />
        <Link to="/nuevo-pedido" className='buttonLink'>Nuevo Pedido</Link>
      </div>
    </div>
  )
}

export default Header;