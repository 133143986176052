import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import Dashboard from '../components/dashboard/Dashboard';
import { LoadingPage } from '../components/loading/Loadings';
import { Navigate } from 'react-router-dom';

const DashboardAdmin = ({ Component }) => {
    
    const { usuario, infoUser } = useAuth();
    const [admin, setAdmin] = useState();
    const [cargando, setCargando] = useState(true);

    useEffect(()=>{
        if(usuario){
            if(infoUser){
                if(infoUser.rol === 'admin'){
                    setAdmin(true)
                    setCargando(false)
                }else{
                    setCargando(false)
                    setAdmin(false)
                }
            }
        }
    },[usuario, infoUser])
    
    return cargando ? < LoadingPage /> : admin ? <Dashboard Component={Component} /> : <Navigate to="/"/>

}

export default DashboardAdmin;