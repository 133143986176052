import React from 'react';
import { Routes, Route } from 'react-router-dom';
//ROOT
import App from './../App';
import RutaPrivada from './RutaPrivada';
// import RutaAdmin from './RutaAdmin';
import LoginUsuarios from '../views/login/LoginUsuarios';
//GENERAL
import Inicio from '../views/landing/Inicio';
import Terminos from '../views/landing/Terminos';
import Politicas from '../views/landing/Politicas';
import SignIn from '../views/login/components/SignIn';
import Register from '../views/login/components/Register';
import ForgotPass from '../views/login/components/ForgotPass';
//USUARIOS
import TerminarRegistro from '../views/login/TerminarRegistro';
import VerificarEmail from '../views/login/VerificarEmail';
import Bienvenida from '../views/login/Bienvenida';
import Profile from '../views/profile/Profile';
import InicioAliadas from '../client/views/InicioAliadas';
import NuevoPedido from '../client/views/NuevoPedido';
import MisPedidos from '../client/views/MisPedidos';
import MiPedido from '../client/views/MiPedido';
//SUPER
import InicioSuper from '../superaliada/views/InicioSuper';
import PedidoAliada from '../superaliada/views/PedidoAliada';
import PedidosAliadas from '../superaliada/views/PedidosAliadas';
import Aliadas from '../superaliada/views/Aliadas';
import Aliada from '../superaliada/views/Aliada';
//ADMIN
import InicioAdmin from '../admin/views/InicioAdmin';
import Pedido from '../admin/views/Pedido';
import Pedidos from '../admin/views/Pedidos';
import SuperAliada from '../admin/views/SuperAliada';
import SuperAliadas from '../admin/views/SuperAliadas';
import Producto from '../admin/views/Producto';
import Productos from '../admin/views/Productos';
import Informes from '../admin/views/Informes';
import Inventario from '../admin/views/Inventario';
//ERRORES
import Error404 from '../views/error/Error404';
import DashboardAdmin from './DashboardAdmin';
import DashboardUser from './DashboardUser';
import DashboardSuper from './DashboardSuper';


const Router = () => {

    return (
        <Routes>
            {/*GENERALES*/}
            <Route path="/" element={<App Component={Inicio}/>} />
            <Route path="/terminos-y-condiciones" element={<App Component={Terminos}/>} />
            <Route path="/politicas-de-privacidad" element={<App Component={Politicas}/>} />
            <Route path="/ingresar" element={<LoginUsuarios Component={SignIn} />} />
            <Route path="/registrate" element={<LoginUsuarios Component={Register} />} />
            <Route path="/recuperar-contrasena" element={<LoginUsuarios Component={ForgotPass} />} />
            
            <Route path="/terminar-registro" element={<RutaPrivada Component={TerminarRegistro} />} />
            <Route path="/bienvenida" element={<RutaPrivada Component={Bienvenida} />} />
            <Route path="/verificar-email" element={<RutaPrivada Component={VerificarEmail} />} />
            <Route path="/perfil" element={<DashboardUser Component={Profile} />} />

            <Route path='/dashboard' element={<DashboardUser Component={InicioAliadas} />} /> 

            {/*ALIADAS*/}
            <Route path="/nuevo-pedido" element={<DashboardUser Component={NuevoPedido} />} />
            <Route path="/mis-pedidos" element={<DashboardUser Component={MisPedidos} />} />
            <Route path="/mi-pedido/:id" element={<DashboardUser Component={MiPedido} />} />

            {/*SUPERAIADAS*/}
            <Route path='/superaliada' element={<DashboardSuper Component={InicioSuper} />} /> 
            <Route path="/aliadas" element={<DashboardSuper Component={Aliadas} />} />
            <Route path="/aliada/:id" element={<DashboardSuper Component={Aliada} />} />
            <Route path="/pedidos-aliadas" element={<DashboardSuper Component={PedidosAliadas} />} />
            <Route path="/pedido-aliada/:id" element={<DashboardSuper Component={PedidoAliada} />} />

            {/*ADMIN*/}
            <Route path='/admin' element={<DashboardAdmin Component={InicioAdmin} />} /> 
            <Route path="/aliadas-admin" element={<DashboardAdmin Component={Aliadas} />} />
            <Route path="/superaliadas" element={<DashboardAdmin Component={SuperAliadas} />} />
            <Route path="/superaliada/:id" element={<DashboardAdmin Component={SuperAliada} />} />
            <Route path="/pedidos" element={<DashboardAdmin Component={Pedidos} />} />
            <Route path="/pedido/:id" element={<DashboardAdmin Component={Pedido} />} />
            <Route path="/productos" element={<DashboardAdmin Component={Productos} />} />
            <Route path="/producto/:id" element={<DashboardAdmin Component={Producto} />} />
            <Route path="/informes" element={<DashboardAdmin Component={Informes} />} />
            <Route path="/inventario" element={<DashboardAdmin Component={Inventario} />} />
            <Route path="/capacitacion" element={<DashboardAdmin Component={Inventario} />} />
            <Route path="/capacitacion/multimedia" element={<DashboardAdmin Component={Inventario} />} />
            <Route path="/capacitacion/publicidad" element={<DashboardAdmin Component={Inventario} />} />
            <Route path="/perfil-admin" element={<DashboardAdmin Component={Profile} />} />
        
            <Route path="*" element={<Error404/>} />
        </Routes>
    );
}

export default Router;
