import React from 'react';
import './loading.css';
import { useConfig } from '../../context/ConfigsContext';

const DivLoading = () =>{
    const {theme} = useConfig();
    return(
        <div className="loadingContainer" data-theme={theme}><div className="lds-ripple"><div></div><div></div></div></div>
    )
}

const LoadingPage = () =>{
    const {theme} = useConfig();

    return(
        <div className="loadingContainer pageLoader" data-theme={theme}><div className="lds-ripple"><div></div><div></div></div></div>
    )
}

const TransLoading = () =>{
    const {theme} = useConfig();
    return(
        <div className="loadingContainerW" data-theme={theme}><div className="lds-ripple"><div></div><div></div></div></div>
    )
}

export {
    DivLoading, 
    LoadingPage, 
    TransLoading
};