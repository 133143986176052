import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { firebase } from '../../../firebase/firebaseConfig';
import { CorreoClave, HuboUnError } from '../../../components/mensajes/MensajesClient';
import { useConfig } from '../../../context/ConfigsContext';
import { Input } from '../../../components/inputs/Input';
import { Button } from '../../../components/buttons/Boton';

const  ForgotPass = ()=>{

    const { OpenModal, CloseModal } = useConfig();
    const [email, setEmail] = useState('');
    const [altEmail, setAltEmail] = useState('');
    const [spnEmail, setSpnEmail] = useState(false);
    const EmailTest = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(email === ''){setSpnEmail(true); setAltEmail('Por favor ingresa un correo'); return;}
        if(!EmailTest.test(email)){setSpnEmail(true); setAltEmail('Por favor ingresa un correo válido'); return;}
        
        try{
            await firebase.auth().sendPasswordResetEmail(email)
            .then(() => {
                OpenModal(<CorreoClave CloseModal={CloseModal}/>)
            }).catch(() => {
                OpenModal(<HuboUnError CloseModal={CloseModal}/>)
            });
        } catch(error){
            OpenModal(<HuboUnError CloseModal={CloseModal}/>)
            switch(error.code){
                case 'auth/user-not-found': setSpnEmail(true); setAltEmail('No se encontró ninguna cuenta con ese correo.'); break;
                default: setSpnEmail(true); setAltEmail('Hubo un error.'); break;
            }
        }
    }

    return (
        <>
            <Helmet><title>Olvidé mi contraseña</title></Helmet>
            <form onSubmit={handleSubmit}>
                <h1 className='formHeader'>Recuperemos tu Contraseña</h1>
                <h2 className='formSub mb-4'>Tranquil@, a todos nos pasa.</h2>
                <Input name={'emaillogin'} type={'email'} label={'Correo Electrónico'} value={email} setValue={setEmail} 
                    alt={altEmail} spn={spnEmail} setSpn={setSpnEmail} fill={'on'} />
                <Button type={"submit"} disable={false} text={'Recuperar Contraseña'} />
                <p className="legalText py-4">¿Ya tienes tu contraseña? <Link to="/ingresar">Ingresa</Link></p>
            </form>
        </>
    )
}

export default ForgotPass;